<template>
  <div>
    <global-table :fields="fields" :table-data="getData" :total-rows="totalRows" :loading="isLoading" :redirect-detail="false" :showAddButton="admin == 1 ? true : false" @addForm="addForm" @editForm="editForm"></global-table>

    <b-modal ref="modalForm" id="addModals" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="md">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">{{ isEdit == true ? "Edit" : "Add" }} Request Status</h3>
      </div>
      <validation-observer v-if="!isLoading" ref="RequestStatus" tag="form" @submit.prevent="submitAdd">
        <b-form>
          <b-form-group label="Name">
            <validation-provider #default="{ errors }" name="Name" rules="required">
              <b-form-input id="Name" placeholder="Name" v-model="formData.name" :state="errors.length > 0 ? false : null"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Flag">
            <validation-provider #default="{ errors }" name="Flag" rules="required">
              <b-form-radio-group :options="options_flag" v-model="formData.flag" name="radio-periode-berlangganan" :state="errors.length > 0 ? false : null"></b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Description">
            <validation-provider #default="{ errors }" name="Description">
              <b-form-textarea id="Description" placeholder="Description" v-model="formData.description" :state="errors.length > 0 ? false : null"></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <loading v-if="isLoading"></loading>
      <div slot="modal-footer" class="float-right">
        <b-button type="button" size="sm" variant="danger" @click="cancelAdd"><i class="fa fa-undo"></i> Back</b-button>
        &nbsp;
        <b-button v-if="admin == 1" type="button" size="sm" variant="primary" @click="submitAdd"><i class="fa fa-save"></i> Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import interfaces from "@/templates/_interfaces.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Loading from "@core/loading/Loading.vue";
import GlobalTable from "@/templates/GlobalTableNew.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    Loading,
    GlobalTable,
    Multiselect
  },
  data() {
    return {
      admin : localStorage.getItem("admin"),
      isEdit: false,
      totalRows: 1,
      isLoading: false,
      data: [],
      formData: interfaces.masterPriorityStatus,
      fields: [
        { key: "name", label: "Name" },
        { key: "flag", label: "Flag" },
        { key: "description", label: "Description" },
        { key: "action", label: "Action", class: "text-center width-80" },
      ],
      options_flag: [
        { text: "Flag 1", value: "1" },
        { text: "Flag 2", value: "2" },
        { text: "Flag 3", value: "3" },
      ],
    };
  },
  methods: {
    init: function () {
      this.isEdit = false;
    },
    getData: function (ctx) {
      let response = axios.post("master/request_status/list", ctx);
      return response.then((response) => {
        const items = response.data.data;
        this.totalRows = response.data.total;
        return items;
      }).catch((error) => {
        return [];
      });
    },
    cancelAdd: function () {
      this.resetForm();
      this.$refs.modalForm.hide();
    },
    addForm: function () {
      this.resetForm();
    },
    editForm: function (a) {
      this.isEdit = true;
      this.formData = a;
      this.$refs.modalForm.show();
    },
    resetForm: function () {
      this.isEdit = false;
      for (var i in interfaces.masterPriorityStatus) {
        interfaces.masterPriorityStatus[i] = null;
      }
      this.formData = interfaces.masterPriorityStatus;
    },
    submitAdd: function () {
      this.$refs.RequestStatus.validate().then((success) => {
        if (success) {
          if (this.isEdit == true) {
            if (confirm("Are you sure want to Update this data ?")) {
              this.isLoading = true;
              axios.put("master/request_status/edit", this.formData).then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Update Form Successful",
                    icon: "EditIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });
              }).catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Update Form Failed",
                    icon: "EditIcon",
                    variant: "danger",
                    text: error.message,
                  },
                });
              }).finally(() => {
                this.isLoading = false;
                this.$refs.modalForm.hide();
                this.resetForm();
                this.init();
              });
            }
          } else {
            if (confirm("Are you sure want to Add this data ?")) {
              this.isLoading = true;
              axios.post("master/request_status/add", this.formData).then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Form Add successful",
                    icon: "EditIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });
              }).catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Form Add Failed",
                    icon: "EditIcon",
                    variant: "danger",
                    text: error.message,
                  },
                });
              }).finally(() => {
                this.isLoading = false;
                this.$refs.modalForm.hide();
                this.resetForm();
                this.init();
              });
            }
          }
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
